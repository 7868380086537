<template>
  <v-app-bar app elevation="4" id="app-bar">
    <v-toolbar-title @click="reloadPage()" class="d-flex align-center" style="cursor: pointer">
      <v-img
        src="/icon.png"
        contain
        max-width="42"
        width="100%"
        class="mr-3 pb-2 pt-2 hidden-xs-only"
      />
      {{ titleText }}
    </v-toolbar-title>

    <v-spacer />

    <template v-if="authUser">
      <v-btn text class="mx-1" :to="{ name: 'user.view', params: { id: authUser.id } }">
        <v-icon class="mr-3">mdi-account</v-icon>
        {{ authUser ? authUser.username : "-" }}
      </v-btn>

      <v-btn outlined color="red" class="mx-1" @click="logoutUser">
        <v-icon class="mr-3">mdi-logout</v-icon>
        Logout
      </v-btn>
    </template>

    <v-app-bar-nav-icon class="hidden-md-and-up" @click="$emit('nav-collapse')" />
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  data: () => ({
    titleText: "KYC Portal",
  }),
  computed: {
    authUser() {
      return this.$auth.user;
    },
  },
  watch: {
    $route() {
      this.setTitle();
    },
  },
  mounted() {
    this.setTitle();
  },
  methods: {
    setTitle() {
      setTimeout(() => (this.titleText = document.title), 100);
    },
    logoutUser() {
      this.$auth.logout();
    },
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
#app-bar {
  &.v-app-bar--fixed {
    left: 0 !important;
  }
}
</style>
